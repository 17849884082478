import '../utilities/utils.scss'
import Error from '../images/404.svg';


function NotFound() {

    return (
        <div className='not-found'>
            <img src={Error} alt="404 - page not found"/>
        </div>
    );
};

export default NotFound;