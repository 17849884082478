import React from "react";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import '../../utilities/utils.scss';
import Hero from '../../images/projects/finmain.png'
import Mojave from '../../images/projects/mojave.jpg'
import Final from '../../images/projects/finary.mp4'

import Finpersona from '../../images/projects/fin-persona.jpg'
import Asis from '../../images/projects/as-is.jpg'
import Finglow from '../../images/projects/fin-glow.png'
import Finfpf from '../../images/projects/fin-pfp.png'
import Finglowhover from '../../images/projects/fin-glow.gif'
import Findetails from '../../images/projects/fin-details.png'
import Fincurate from '../../images/projects/fin-curate.png'

import Finfpfold from '../../images/projects/fin-pfp-old.png'
import Finfpfnew from '../../images/projects/fin-pfp-new.png'

import Findetailsold from '../../images/projects/fin-details-old.png'
import Findetailsnew from '../../images/projects/fin-details-new.png'

import Finsortold from '../../images/projects/fin-sort-old.png'
import Finsortnew from '../../images/projects/fin-sort-new.png'

import CaseHeader, { DarkBG, LightBG, HorHeader, VerSection } from '../CaseHeader';

function Finary() {


    return (
        <div className="case-study BSC">
            <CaseHeader
                headerImage={Hero}
                className="bsc-proj-header"
                title="Finary"
                subtitle="Discord meets Robinhood. Adding NFT gallery & profile picture support to a fin-tech platform"
                blurb="For my Master's Capstone project, my team and I collaborated with the design agency ElectroPath on a project for their client, Finary. Our goal was to make Finary the best platform for displaying and sharing NFTs, regardless of where they were purchased. The focus was on security and trust."
                problem="Finary users want to share, show off, and trade their NFTs. For Finary to become the hub for crypto discussions, it must integrate NFTs in a robust, decentralized way."
                outcome="We delivered designs for an NFT gallery, before-and-after journey maps, user research, and an interactive prototype using the Finary Design System."
                role="Product designer"
                time="Jan '22 - Apr '22"
                team="6 designers"
            />
        <div id="final">
            <div className='finary-bg' style={{ backgroundImage: `url('${Mojave}')`, backgroundSize: 'cover' }}>
                <video loop autoplay width="60%">
                    <source src={Final} type="video/mp4" />
                </video>
            </div>
        </div>
        <DarkBG id="process">
            <VerSection
                top="Design brief"
                        bottom="Support NFT sharing on Finary"
                        body="How might we help users display their NFT collections on their profiles and give them ways to show them off?">
                <div className='span-1'>
                    <h3>01</h3>
                </div>
                <div className='span-3'>
                    <h3>NFT Curation</h3>
                    <p>How do NFTs look when people view someone’s collection on their profile?</p>
                </div>

                <div className='span-1'>
                    <h3>02</h3>
                </div>
                <div className='span-3'>
                    <h3>NFT Profile Picture</h3>
                    <p>How do people show off NFTs in specialized NFT profile pictures?</p>
                </div>
            </VerSection>


        </DarkBG>
        <LightBG id="research-plan">
            <VerSection
                top="User research"
                bottom="process & plan"
                body="We conducted different forms of research to understand this problem. The research provided us with a deeper understanding of the NFT sharing, curation and collection experience.">
                <div className='span-1'>
                    <h3>01</h3>
                </div>
                <div className='span-3'>
                    <h3>Observation Study</h3>
                    <p>We joined Finary and observed how people shared NFTs and discussed them. This helped us understand how users naturally discussed NFTs.</p>
                </div>

                <div className='span-1'>
                    <h3>02</h3>
                </div>
                <div className='span-3'>
                    <h3>Competitor Analysis</h3>
                    <p>We analyzed 7 competitors—MetaMask, MetaLink, OpenSea, SuperRare, Rainbow, Coinbase, and BubbleHouse—to see how they enabled NFT sharing and collection curation.</p>
                </div>
                <div className='span-1'>
                    <h3>03</h3>
                </div>
                <div className='span-3'>
                    <h3>9 Semi-Structured Interviews</h3>
                    <p>We conducted 9 semi-structured interviews, including 2 Finary users, NFT veterans, and newcomers. Our goal was to understand NFT sharing experiences, methods, benefits, and risks.</p>
                </div>
                <div className='span-1'>
                    <h3>04</h3>
                </div>
                <div className='span-3'>
                    <h3>24 Survey Respondents</h3>
                    <p>We created a survey completed by 24 people to understand their feelings about NFTs, their value, and their desire to share them. We targeted both NFT veterans and newcomers.</p>
                </div>
            </VerSection>

        </LightBG>
        <DarkBG id="research-findings">
            <VerSection
                top="User research"
                bottom="results & insights"
                body="We found that NFTs are major investments for people, and they worry about theft if displayed in a gallery that was open to the public.">
                <div className='span-1'>
                    <h3>67%</h3>
                </div>
                <div className='span-3'>
                    <h3>Of participants want to keep NFTs private</h3>
                    <p>When looking to purchase and share NFTs, users are worried about scams and becoming targets for theft. As a result, they prefer to hide their most valuable ones.</p>
                </div>

                <div className='span-1'>
                    <h3>57%</h3>
                </div>
                <div className='span-3'>
                    <h3>Of participants use multiple platforms</h3>
                    <p>Users need different platforms, wallets, and markets to research, purchase, share, and trade NFTs. There is no centralized way to do all these tasks.</p>
                </div>
                <div className='span-1'>
                    <h3>69%</h3>
                </div>
                <div className='span-3'>
                    <h3>Of participants see NFTs as investments</h3>
                    <p>While NFTs do have their artistic value, most NFT veterans are interested in NFTs for their investment value.</p>
                </div>
            </VerSection>

        </DarkBG>
        <LightBG id="problem">
            <div className="container-1200">
                <h3>Problem statement</h3>
                <h2>Sharing and validating NFTs is a risky, impersonal, and fragmented experience. How can we help users share NFTs safely and understand the value of NFTs they encounter?</h2>
            </div>

        </LightBG>
        <DarkBG id="persona">
            <HorHeader 
                top="Introducing our"
                bottom="persona — Nathan"
                body="We created a persona to ground every design decision in research and clarify who we were designing for."/>

            <div className='container-1200'>
                <Zoom>
                    <img
                        alt=" "
                        src={Finpersona}
                        width="100%"
                    />
                </Zoom>
            </div>

        </DarkBG>
        <LightBG id="journey-map">
            <HorHeader 
                top="Journey map"
                bottom="for NFT sharing"
                body="We created a customer journey map to align stakeholders and highlight pain points in the current NFT buying and sharing experience."/>
            <div className='container-1200'>
                <Zoom>
                    <img
                        alt=" "
                        src={Asis}
                        width="100%"
                    />
                </Zoom>
            </div>

        </LightBG>
        <DarkBG id="feature-1">
            <HorHeader
                top="Profile pictures"
                bottom="and NFT rarity"
                body="We used a hexagon with a soft border for NFT profile pictures. To add some fun, I included a colored glow on hover, inspired by video game item rarity." />

            <div className='container-1200'>
                <div className='col-2-grid'>
                    <div className='span-1'>
                        <Zoom>
                            <img
                                alt=" "
                                src={Finglowhover}
                                width="100%"
                            />
                        </Zoom>
                    </div>
                    <div className='span-1'>
                        <Zoom>
                            <img
                                alt=" "
                                src={Finfpf}
                                width="100%"
                            />
                        </Zoom>
                    </div>
                    <div className='span-2'>
                        <Zoom>
                            <img
                                alt=" "
                                src={Finglow}
                                width="100%"
                            />
                        </Zoom>
                    </div>
                </div>
            </div>

        </DarkBG>
        <DarkBG id="feature-2">
            <HorHeader 
                top="NFT details"
                bottom="for better research"
                body="Researching NFTs today is a fragmented experience. Users need to visit multiple platforms to validate the value of NFTs. We created a details page for information in one place."/>

                <Zoom>
                    <img
                        alt=" "
                        src={Findetails}
                        width="100%"
                    />
                </Zoom>
        </DarkBG>
        <DarkBG id="feature-3">
            <HorHeader
                top="Gallery curation:"
                bottom="hiding & pinning NFTs"
                body="To address fear of theft, we allowed users to hide NFTs. They can also pin NFTs they are most proud of for clout." />

            <Zoom>
                <img
                    alt=" "
                    src={Fincurate}
                    width="100%"
                />
            </Zoom>
        </DarkBG>
        <LightBG id="usability">
            <HorHeader
                top="Usability tests"
                bottom="and iterations"
                body="We conducted usability tests with 5 NFT holders, including a frequent Finary user. We wanted to test the clarity, completion, and comprehension of our features." />

                <div className='container-1200'>
                    <div className='col-2-grid before-after'>
                        <div className='span-1'>
                            <Zoom>
                                <img
                                    alt=" "
                                    src={Finfpfold}
                                    width="100%"
                                />
                            </Zoom>
                            <h4>Before</h4>
                            <p>Some struggled to recognize NFT profile pictures because the overly rounded edges made them look like circles at smaller sizes.</p>
                        </div>
                        <div className='span-1'>
                            <Zoom>
                                <img
                                    alt=" "
                                    src={Finfpfnew}
                                    width="100%"
                                />
                            </Zoom>
                            <h4>After</h4>
                                <p>We slightly adjusted the border-radius to make it stand out from circle avatars, while still keeping it rounded.</p>
                        </div>
                        <div className='span-1'>
                            <Zoom>
                                <img
                                    alt=" "
                                    src={Findetailsold}
                                    width="100%"
                                />
                            </Zoom>
                            <h4>Before</h4>
                            <p>Users felt the sidebar was too small. They wanted more details about artists and to see modules rearranged.</p>
                        </div>
                        <div className='span-1'>
                            <Zoom>
                                <img
                                    alt=" "
                                    src={Findetailsnew}
                                    width="100%"
                                />
                            </Zoom>
                            <h4>After</h4>
                            <p>We widened the sidebar, added rarity colors, artist details and reorganize the sections based on user feedback</p>
                        </div>
                        <div className='span-1'>
                            <Zoom>
                                <img
                                    alt=" "
                                    src={Finsortold}
                                    width="100%"
                                />
                            </Zoom>
                            <h4>Before</h4>
                            <p>Users found it tedious having to manage their gallery one by one.</p>
                        </div>
                        <div className='span-1'>
                            <Zoom>
                                <img
                                    alt=" "
                                    src={Finsortnew}
                                    width="100%"
                                />
                            </Zoom>
                            <h4>After</h4>
                            <p>We added bulk editing and a sorting dropdown that allows users to sort by A-Z, floor price and last added.</p>
                        </div>
                    </div>
                </div>

        </LightBG>
        <DarkBG id="final-designs">
            <HorHeader
                top="Final designs"
                bottom="NFT gallery & details"
                body="With that, we finished the capstone project with Finary and Electropath. Our last step was a closing meeting to present our work and hand over the designs." />

            <div className='finary-bg' style={{ backgroundImage: `url('${Mojave}')`, backgroundSize: 'cover' }}>

                <video loop autoplay width="60%">
                    <source src={Final} type="video/mp4" />
                </video>

            </div>
        </DarkBG>
        </div>
    );

}
export default Finary;