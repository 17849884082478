import React from 'react';
import './Home.css';
import './card.scss';
import TextTransition, { presets } from 'react-text-transition';
import Twitter from '../images/twitter.svg';
import LinkedIn from '../images/li.svg';
import Me from '../images/me.png';
import Notifhero from '../images/projects/notifhero.png'
import FDhero from '../images/projects/firstdrafthero.gif'
import Metasetting from '../images/projects/metasettings.gif'
import BSChero from '../images/projects/bscheader.png'
import Romhero from '../images/projects/AR.gif'
import PWAhero from '../images/projects/pwahero.png'
import Octoshop from '../images/projects/octoshop.png'
import Mihero from '../images/projects/mihero.png'
import Mentihero from '../images/projects/men-info-1.jpg'
import BPhero from '../images/projects/bphero.png'
import Arrow from '../images/arrow-up-right.svg'
import Lock from '../images/projects/lock.svg'
import Finhero from '../images/projects/fin-glow.gif'
import { Fade } from "react-awesome-reveal";


const TEXTS = [
    'I could eat my weight in Korean short ribs', 
    'I value empathy & vulnerability',
    'I studied stats, CS & neuroscience', 
    "I've been to 23 countries", 
    'I started designing at the age of 10',
    "I love playing minecraft",
    "Bubble tea is my favourite food group",
    "I've climbed an active volcano",
];

const PROJECT_LIST = [
  {
    name: 'First Draft relaunch',
    company: ['Figma', '2024'],
    image: FDhero,
    desc: "Redesigning the underlying design system, creating web and mobile wireframing kits and supporting redesigning the UI as part of Figma's post-config relaunch of First Draft.",
    link: 'https://www.figma.com/blog/figma-ai-first-draft/',
    class: 'figma',
    status: 'Shipped',
    external: true,
    target: '_blank'
  },
  {
    name: 'Notifications',
    company: ['Figma', '2024'],
    image: Notifhero,
    desc: 'Improving the information architecture and parseability of Figma’s notification feed with a visual redesign. This includes a scalable and robust system to support future notifications.',
    link: 'https://x.com/RobertJBye/status/1802823539541827874',
    class: 'figma',
    status: 'Shipped',
    external: true,
    target: '_blank'
  },
  {
    name: 'ChromeOS PWA',
    company: ['Figma', '2024'],
    image: PWAhero,
    desc: 'Adding progressive web app (PWA) support for downloading Figma on Chromebooks and ChromeOS. Announced and presented during the Google I/O 2024 Chrome keynote.',
    link: 'https://chromeos.dev/en/posts/io-2024#figma-launches-their-tabbed-pwa',
    class: 'figma',
    status: 'Shipped',
    external: true,
    target: '_blank'
  },
  {
    company: ['Instok', '2022'],
    name: 'Octoshop',
    image: Octoshop,
    external: true,
    desc: 'Co-founded a startup for online shopping with price comparison and cashback. I designed and devloped the design system and core flows. 130k users and 300k ARR in 18 months. Acquired by Ibotta.',
    link: 'https://theygotacquired.com/software/octoshop-acquired-by-ibotta/',
    class: 'menti',
    status: "Acquired",
  },
  {
    company: ['Meta', '2022'],
    name: 'Account settings',
    image: Metasetting,
    desc: "Redesigning Facebook's account settings to match the new, modernized interface and building a framework for future account setting additions. This change impacts 9 million+ users daily. ",
    link: 'https://www.facebook.com/settings',
    class: 'rom',
    status: 'Shipped',
    external: true,
    target: '_blank'


  },

  {
    company: ['Side project', 'Case study', '2022'],
    name: 'Museum AR/VR',
    image: Romhero,
    desc: 'A side project exploring AR/VR technology to make museums more engaging. This case study includes a design system, brand identity kit, web and mobile app and mobile AR add headset VR experiences. ',
    link: '/projects/rom',
    class: 'rom',
    status: null,

  },
  {
    company: ['Finary', 'Case study', '2022'],
    name: 'NFT gallery',
    image: Finhero,
    desc: 'Finary(YC21) is a fin-tech investment platform and chat forum. This project focused on allowing users to select NFT profile pictures and feature their collection in an NFT gallery on their profile.',
    link: '/projects/finary',
    class: 'finary',
    status: null,
  },


  {
    company: ['Side project', 'Case study'],
    name: 'Menti',
    image: Mentihero,
    desc: 'Side project and coded prototype show casing a platform for designers to find mentorship, get company insider tips and tricks and practice for design interviews',
    link: '/projects/menti',
    class: 'menti',
    status: null,
  },
  {
    name: 'Design system',
    company: ['Boston Scientific', 'Case study', '2020'],
    image: BSChero,
    desc: "Revamping and unifying Boston Scientific's design system for their suite of products. This case study focuses on my work on redesigning pictograms for clarity.",
    link: '/projects/bsc',
    class: 'bsc',
    status: 'locked',
  },
  {
    company: ['Blueprint', 'Case study', '2020'],
    name: 'Move management',
    image: BPhero,
    desc: 'A 0-1 web application for a non-profit that supports move coordination. We automated a waiver signing process and built templates for more streamlined and consistent planning',
    link: '/projects/blueprint',
    class: 'blueprint',
    status: null,
  },
  // {
  //   company: 'Meta',
  //   name: 'Internship',
  //   logo: Metalogo,
  //   image: Metahero,
  //   desc: 'My time at Meta as a Product Design Intern on the horizontal team called Product Foundations',
  //   link: '/projects/meta',
  //   class: 'meta',
  //   status: null,
  // },
  {
    company: ['Side project'],
    name: 'MI scheduler',
    image: Mihero,
    desc: 'Improving the class schedule planning process for Master of Information students that visualizes potential schedules. This tool sees 500 new users every calendar year. ',
    link: '/projects/mi',
    class: 'mi',
    status: null,
  },

]
function Home() {
  const [index, setIndex] = React.useState(0);
  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      2000, // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);


  return (
    <div className="App">
      <div className="grad-blur-home">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>


      <div className='jumbotron flex'>
        <div className='left flex flex-1 flex-column'>
          <Fade direction='up' triggerOnce cascade>

            <div className='flex-1'>
              <svg width="1" height="1">
                <defs>
                  <filter id="blur">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="2 13" />
                  </filter>
                </defs>
              </svg>
              <div style={{ position: 'relative' }}>
                <div class="vblur">
                  <h1 aria-hidden="true">Hello, I'm Tammy</h1>
                </div>
                <h1>Hello, I'm Tammy</h1>
              </div>
              <h2>
                <TextTransition direction="down" springConfig={presets.gentle}>{TEXTS[index % TEXTS.length]}</TextTransition>
              </h2>
            </div>
            <div className='job-section flex'>
              <Fade direction='up' triggerOnce cascade className='flex-1'>
              <div className='flex-1'>
                <h3>Currently</h3>
                <h4>Gen AI @ Figma</h4>
              </div>
              <div className='flex-1'>
                <h3>Previously at</h3>
                <h4>Meta, Xbox & Instok</h4>
              </div>
              </Fade>
            </div>

          </Fade>

        </div>
        <div className='right flex flex-1 flex-column'>
          <Fade direction='up' cascade triggerOnce delay={200}>
              <img src={Me} alt="Black and white headshot of Tammy in a scalloped circle shape" className="mee" height='100px' width='100px' />
          </Fade>
          <Fade direction='up' triggerOnce delay={230}>
          <div>
          <h3>Nice to meet cha'</h3>
          <h4>I am your resident jack-of-all-trades: product designer, front-end engineer, illustrator and design systems fanatic. I like building cool things and solving hard problems.
          </h4>
            </div>
          </Fade>
          <div className='flex socials'>
          <Fade direction='up' triggerOnce cascade delay={700} damping={0.1}>
              <a href="https://x.com/taamannae" rel="noreferrer" target='_blank'><img src={Twitter} alt="Twitter"/></a>
              <a href="https://www.linkedin.com/in/taamannae/" rel="noreferrer" target='_blank'><img src={LinkedIn} alt="LinkedIn" /></a>

            </Fade>
          </div>
        </div>
      </div>
      <div className='root-container'>
        {renderCards()}
      </div>

    </div>
  );
}

function renderCards() {
  return (
  <div className='flex home-card-container'>
      {PROJECT_LIST.map((item, i) => {
        var status = null;

        if (item.status && item.status.includes('Shipped')) {
          status = <div className='status'>
            <span>{item.status}</span>
            {item.external && <img className="shipped-arrow" src={Arrow} alt="Enternal icon"/>}
          </div>
        }
        else if (item.status && item.status.includes('Acquired')) {
          status = <div className='status'>
            <span>{item.status}</span>
            {item.external && <img className="shipped-arrow" src={Arrow} alt="External icon"/>}
          </div>

        } else if (item.status === 'locked') {
          status = (
            <div className='double-status flex'>
              <div className='status lock'><img src={Lock} alt="locked"/></div>
              <div className='status'>
                <span>Shipped</span>
                {item.external && <img className="shipped-arrow" src={Arrow} alt="External icon"/>}
              </div>
            </div>
          )
        }

        if (i === 0 || i === 1 || i === 2) {
          return (
            <Fade direction="up" delay={200 + i * 100 + 50} triggerOnce className='project-card'>
              <a href={item.link} className={'fanout home-card ' + item.class} target={item.target}>

                <div className='preview-image' style={{ backgroundImage: `url('${item.image}')` }}>

                </div>
                <div className='flex home-card-left flex-1 flex-column'>
                  <div className='flex-1 comp-logo'>
                    <div className='flex comp-section'>
                      <h3>{item.name}</h3>
                      <div style={{marginTop: "2px"}}>{status}</div>
                    </div>
                  </div>
                  {/* <p>{item.company}</p> */}
                  <p>{item.desc}</p>

                  <div className='project-context'>
                    {item.company.map(thing => (
                      <><h4>{thing}</h4>
                        <h4 className='dot'>·</h4>
                      </>
                    )

                    )}
                  </div>
                </div>
              </a>
            </Fade>

          );

        }
        return(
          <div className='project-card'> 
          <a href={item.link} className={'fanout home-card ' + item.class} target={item.target}>

            <div className='preview-image' style={{backgroundImage: `url('${item.image}')`}}>

            </div>
              <div className='flex home-card-left flex-1 flex-column'>
                <div className='flex-1 comp-logo'>
                  <div className='flex comp-section'>
                    <h3>{item.name}</h3>
                    <div>{status}</div>
                  </div>
                </div>
                {/* <p>{item.company}</p> */}
                <p>{item.desc}</p>

                <div className='project-context'>
                {item.company.map(thing => (
                    <><h4>{thing}</h4>
                    <h4 className='dot'>·</h4>
                    </>
                  )

                )}
                </div>
              </div>
          </a>
        </div>

        );

    })}
  </div>

  )

}

export default Home;
