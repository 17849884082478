import React from "react";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import '../../utilities/utils.scss';
import Hero from '../../images/projects/romhero.jpg'
import Marquee from "react-fast-marquee";

import Rom1 from '../../images/projects/rom1.mp4'
import Rom2 from '../../images/projects/rom2.mp4'
import Rom3 from '../../images/projects/rom3.mp4'
import Rom4 from '../../images/projects/rom5.mp4'
import Rom5 from '../../images/projects/AR.mp4'


import Lofi from '../../images/projects/rom-sketches.png'
import Old1 from '../../images/projects/rom-1-old.jpg'
import New1 from '../../images/projects/rom-1-new.jpg'
import Old2 from '../../images/projects/rom-2-old.jpg'
import New2 from '../../images/projects/rom-2-new.jpg'
import Old3 from '../../images/projects/rom-3-old.jpg'
import New3 from '../../images/projects/rom-3-new.jpg'

import Deck0 from '../../images/projects/romdeck-0.jpg'
import Deck1 from '../../images/projects/romdeck-1.jpg'
import Deck2 from '../../images/projects/romdeck-2.jpg'
import Deck3 from '../../images/projects/romdeck-3.jpg'
import Deck4 from '../../images/projects/romdeck-4.jpg'
import Deck5 from '../../images/projects/romdeck-5.jpg'
import Deck6 from '../../images/projects/romdeck-6.jpg'
import Deck7 from '../../images/projects/romdeck-7.jpg'
import Deck8 from '../../images/projects/romdeck-8.jpg'
import Deck9 from '../../images/projects/romdeck-9.jpg'
import Deck10 from '../../images/projects/romdeck-10.jpg'
import Deck11 from '../../images/projects/romdeck-11.jpg'

import Logo1 from '../../images/projects/rom-logo-1.jpg'
import Logo2 from '../../images/projects/rom-logo-2.jpg'
import Logo3 from '../../images/projects/rom-logo-3.jpg'

import DS1 from '../../images/projects/rom-ds-1.jpg'
import DS2 from '../../images/projects/rom-ds-2.jpg'


import CaseHeader, { DarkBG, LightBG, HorHeader, VerSection } from '../CaseHeader';

function Rom() {


    return (
        <div className="case-study BSC">
            <CaseHeader
                headerImage={Hero}
                className="bsc-proj-header"
                title="Rom"
                subtitle="A mobile app and AR/VR experience to make the Royal Ontario Museum more engaging"
                blurb="I'm passionate about emerging technology, so I decided to learn VR/AR prototyping and design to improve my skills. I created Living Museum, as a personal project to make museum experiences more engaging. It brings the past alive with interactive and immersive stories, showing how artifacts are connected, their histories, and fun facts. This project is not affiliated with the Royal Ontario Museum in Toronto; it's just for practice."
                problem="How might we make museum experiences more immersive, exciting, and story-driven?"
                outcome="I created a web, mobile AR and Unity VR experience that makes digital museum visits more fun through scavenger hunts, personal collection curation and immersive exhibits"
                role="Product designer"
                time="Jan '22 - Apr '22"
            />

        <DarkBG id="final">
            <HorHeader
                top="Final designs"
                bottom="and solutions"
                body="Over 4 months, I developed a multimodal experience for museums, including desktop, mobile, AR, and VR applications, along with a supporting design system. This project aims to make museum experiences more immersive and interactive." />
                    <div className='final-rom'>

            <div className='col-12-grid'>
                <div className='span-9'>
                    <video loop autoplay width="100%" className="mento-video">
                        <source src={Rom1} type="video/mp4" />
                    </video>
                </div>
                <div className='span-3 final-showcase'>
                    <h2>Interactive Scavenger Hunts</h2>
                    <div><p>79% of survey respondents wished museums were more engaging. Users engage with museum artifacts through an interactive scavenger hunt experience. Online exhibitions can provide users with the option to dig deeper into the collection while also learning fun facts.</p></div>
                </div>

            </div>
                <div className='col-12-grid'>
                    <div className='span-3 final-showcase'>
                        <h2>Robust filtering system</h2>
                        <div><p>Giving users a robust filtering system helps them find exact what they need. Many local museum goers already know the exhibits. They would rather find specific items they enjoy.</p></div>
                    </div>
                    <div className='span-9'>
                        <video loop autoplay width="100%" className="mento-video">
                            <source src={Rom2} type="video/mp4" />
                        </video>
                    </div>


                </div>
                <div className='col-12-grid'>
                    <div className='span-9'>
                        <video loop autoplay width="100%" className="mento-video">
                            <source src={Rom3} type="video/mp4" />
                        </video>
                    </div>
                    <div className='span-3 final-showcase'>
                        <h2>Personal collections & exhibits</h2>
                        <div><p>In order to preserve artifacts,museums maintain a barrier between the person and the artifact, leaving some museum goers feeling disconnected. This application allows users to create personal connection with artifacts by building collections for themselves or sharing them with others.</p></div>
                    </div>

                </div>
                <div className='col-12-grid'>
                    <div className='span-3 final-showcase'>
                        <h2>Artifact Extender</h2>
                        <div><p>Enabling art to come alive and spill into the real world through this mobile and headset AR experience. Users are immersed into the world of the artifact, leaving them with a deeper understanding of the piece.</p></div>
                    </div>
                    <div className='span-2'>
                        <video loop autoplay width="100%" className="mento-video">
                            <source src={Rom4} type="video/mp4" />
                        </video>
                    </div>
                    <div className='span-7'>
                                <video loop autoplay width="100%" className="mento-video">
                            <source src={Rom5} type="video/mp4" />
                        </video>
                    </div>

                </div>
                </div>

        </DarkBG>
        <LightBG id="research">
            <VerSection
                top="User research"
                bottom="findings & results"
                body="To get a baseline understanding of the public space, I conducted 2 semi-structured interviews. Additionally, 59 individuals completed a survey. Here are the findings.">
                <div className='span-1'>
                    <h3>97%</h3>
                </div>
                <div className='span-3'>
                    <h3>Of respondents do not engage with online museum content</h3>
                    <p>Many participants said they didn't know this type of content existed. One person said, "It's not interesting unless it's in person. I can look at pictures and read anywhere. It's just static information."</p>
                </div>
                <div className='span-1'>
                    <h3>79%</h3>
                </div>
                <div className='span-3'>
                    <h3>Of respondents wish museums were more engaging</h3>
                    <p>Participants agreed that museums aren't interactive and have too much text. One person mentioned they dislike the "sense of sacredness requiring silence and reverence."</p>
                </div>

                <div className='span-1'>
                    <h3>66%</h3>
                </div>
                <div className='span-3'>
                    <h3>Of respondents prefer visiting museums with other people</h3>
                    <p>This is mainly because others can act as impromptu tour guides. Participant one says, "It's good to go with a diverse group because some people know things others don't, so we all share our own stories."</p>
                </div>
            </VerSection>


        </LightBG>
        <DarkBG id="low-fi">
            <HorHeader 
                top="Low-fi sketches"
                bottom="and ideas"
                body="I ideated over potential solutions and user flows. I used sketches to solidify thoughts and draw out how I wanted interactions to work."/>

                <Zoom>
                    <img
                        alt=" "
                        src={Lofi}
                        width="100%"
                        style={{mixBlendMode: 'screen'}}
                    />
                </Zoom>

        </DarkBG>
        <LightBG id="usability">
            <HorHeader 
                top="Usability testing"
                bottom="findings & iterations"
                body="I conducted a small usability test with 4 individuals. I was looking to test clarity, completion and comprehension of the different features."/>

                    <div className='container-1200'>
                        <div className='col-2-grid before-after'>
                            <div className='span-1'>
                                <Zoom>
                                    <img
                                        alt=" "
                                        src={Old1}
                                        width="100%"
                                    />
                                </Zoom>
                                <h4>Before</h4>
                                <p>The 'view' button on collections was unclear. Some testers thought it was a view count and not a button at all.</p>
                            </div>
                            <div className='span-1'>
                                <Zoom>
                                    <img
                                        alt=" "
                                        src={New1}
                                        width="100%"
                                    />
                                </Zoom>
                                <h4>After</h4>
                            <p>To address this, I updated the copy from simply “View” to “View Collection.”</p>
                            </div>
                            <div className='span-1'>
                                <Zoom>
                                    <img
                                        alt=" "
                                        src={Old2}
                                        width="100%"
                                    />
                                </Zoom>
                                <h4>Before</h4>
                                <p>The term "timeline" was confusing to some users. They thought the button would show them a history of items they have looked at.</p>
                            </div>
                            <div className='span-1'>
                                <Zoom>
                                    <img
                                        alt=" "
                                        src={New2}
                                        width="100%"
                                    />
                                </Zoom>
                                <h4>After</h4>
                                <p>To address this, I updated the text from "timeline" to “Artifact History.”</p>
                            </div>
                            <div className='span-1'>
                                <Zoom>
                                    <img
                                        alt=" "
                                        src={Old3}
                                        width="100%"
                                    />
                                </Zoom>
                                <h4>Before</h4>
                                <p>Users requested a way to add tags to collections since searching for them would be too vague otherwise.</p>
                            </div>
                            <div className='span-1'>
                                <Zoom>
                                    <img
                                        alt=" "
                                        src={New3}
                                        width="100%"
                                    />
                                </Zoom>
                                <h4>After</h4>
                                <p>To address this, I added a textfield to allow users to add keywords. For brevity’s sake, keywords will be accepted using comma separations.</p>
                            </div>
                        </div>
                    </div>


        </LightBG>
        <DarkBG id="brand-kit">
            <HorHeader
                top="Building a "
                bottom="brand identity"
                body="To support the design, I created a brand guideline kit. It includes tone of voice, logos, colors, fonts, icons, images, and product vision." />

            <Marquee className="marquee" pauseOnHover speed={200}>
                <Zoom> <img alt=" " src={Deck0} width=""/></Zoom>
                <Zoom> <img alt=" " src={Deck1} width=""/></Zoom>
                <Zoom> <img alt=" " src={Deck2} width=""/></Zoom>
                <Zoom> <img alt=" " src={Deck3} width=""/></Zoom>
                <Zoom> <img alt=" " src={Deck4} width=""/></Zoom>
                <Zoom> <img alt=" " src={Deck5} width=""/></Zoom>
                <Zoom> <img alt=" " src={Deck6} width=""/></Zoom>
                <Zoom> <img alt=" " src={Deck7} width=""/></Zoom>
                <Zoom> <img alt=" " src={Deck8} width=""/></Zoom>
                <Zoom> <img alt=" " src={Deck9} width=""/></Zoom>
                <Zoom> <img alt=" " src={Deck10} width=""/></Zoom>
                <Zoom> <img alt=" " src={Deck11} width=""/></Zoom>

            </Marquee>

        </DarkBG>
        <LightBG id="new-logo">
            <HorHeader
                top="A new logo"
                bottom="system"
                body="To give the design a sense of regality, tradition, and a touch of modernity, I created a new logo. I made full, partial, and logo mark versions for scalability." />

            <div className='container-1200'>
                <div className='col-2-grid'>
                    <div className='span-1'>
                        <Zoom>
                            <img
                                alt=" "
                                src={Logo1}
                                width="100%"
                            />
                        </Zoom>
                    </div>
                    <div className='span-1'>
                        <Zoom>
                            <img
                                alt=" "
                                src={Logo2}
                                width="100%"
                            />
                        </Zoom>
                    </div>
                    <div className='span-2'>
                        <Zoom>
                            <img
                                alt=" "
                                src={Logo3}
                                width="100%"
                            />
                        </Zoom>
                    </div>
                </div>
            </div>

        </LightBG>
        <DarkBG id="design-system">
            <HorHeader
                top="A robust"
                bottom="Design system"
                body="I created a comprehensive design system that goes beyond what is used in the final design. I built this following WCAG AA accessibility guidelines" />

            <div className='col-2-grid'>
                <Zoom>
                    <img
                        alt=" "
                        src={DS1}
                        width="100%"
                    />
                </Zoom>
                <Zoom>
                    <img
                        alt=" "
                        src={DS2}
                        width="100%"
                    />
                </Zoom>
            </div>

        </DarkBG>
        <LightBG id="final-design">
            <HorHeader
                top="Final VR museum"
                bottom="experience"
                body="Putting everything together, I created a VR museum scavenger hunt experience that truly immerses the user and gives them the ability to actually hold and touch museum artifacts." />

            <div className='container-1200 video-container'>
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/YJ_4bE43nAg?si=lBfYRI5kcgQ7KvpC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </LightBG>
        </div>
    );

}
export default Rom;