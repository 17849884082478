import React, { useRef } from "react";
import './BSC.scss';
import '../../utilities/utils.scss';
import BSChero from '../../images/projects/bscheader.png'
import Final from '../../images/projects/bsc-final.png'
import Bscexplore from '../../images/projects/bscexplore.jpg'
import Bscold from '../../images/projects/bscold.png'
import Bscnew from '../../images/projects/bscnew.png'
import Bsc2old from '../../images/projects/bscq2old.jpg'
import Bsc2new from '../../images/projects/bscq2new.jpg'
import Bscold1 from '../../images/projects/bscold1.jpg'
import Bscnew1 from '../../images/projects/bscnew1.jpg'
import Bscoldres1 from '../../images/projects/bscq1res2.jpg'
import Bscnewres2 from '../../images/projects/bscq2res2.jpg'
import Bsciter1 from '../../images/projects/bsciteration1.jpg'
import Bsciter2 from '../../images/projects/bsciterationfinal.jpg'
import NorthStar from '../../images/projects/bscnorthstar.jpg'
import CaseHeader, { DarkBG, LightBG, HorHeader, VerSection } from '../CaseHeader';

function BSC() {
    return (
        <div className="case-study BSC">
            <CaseHeader
                headerImage={BSChero}
                className="bsc-proj-header"
                title="Boston Scientific"
                subtitle="A consistent and accessible design system for Boston Scientific's suite of products"
                blurb="Boston Scientific is a health tech company focused on improving the health of their users with innovative technologies. Boston Scientific wished to create a more cohesive design across all of their mobile applications. This case study focuses on my work on icons."
                problem="Create a cohesive design system that is also accessible to Boston Scientific's older users and that can be used across all apps."
                outcome="We delivered a design system in 4 months. I worked on a icon set that improved in context recognition from 56% to 98%"
                role="Product designer"
                time="Sep '20 - Dec '20"
                team="5 designers"
            />
            <DarkBG id="final-system">
                    <HorHeader 
                        top="The final"
                        bottom="design system" 
                        body="Over the course of four months, we developed a trustworthy, modern and comprehensive design system that was easier for Boston Scientific's older adult demographic to use."/>

                <div className='container-1200'>
                    <img className='span-4' src={Final} />
                </div>

            </DarkBG>

            <LightBG id="process">
                <VerSection top="Our design" bottom="process" body="Our process involved digging into Boston Scientific's existing styles, finding issues, exploring new styles, testing ideas and finally, delivering the system.">
                    <div className='span-1'>
                        <h3>01</h3>
                    </div>
                    <div className='span-3'>
                        <h3>Conduct an audit</h3>
                        <p>I owned the design system's color tokens from conception, auditing, iteration to delivery.</p>
                    </div>
                    <div className='span-1'>
                        <h3>02</h3>
                    </div>
                    <div className='span-3'>

                        <h3>Set design principles</h3>
                        <p>We decided on what principles to follow to create the most effective system</p>
                    </div>

                    <div className='span-1'>
                        <h3>03</h3>
                    </div>
                    <div className='span-3'>
                        <h3>Design, design, design</h3>
                        <p>We explored different styles and ideas for the design system in Figma.</p>
                    </div>

                    <div className='span-1'>
                        <h3>04</h3>
                    </div>
                    <div className='span-3'>
                        <h3>Test</h3>
                        <p>We tested our hypotheses for icon styles, style reception and accessibility.</p>
                    </div>

                    <div className='span-1'>
                        <h3>05</h3>
                    </div>
                    <div className='span-3'>
                        <h3>Iterate</h3>
                        <p>We iterated over and over with the Boston Scientific design team to land the perfect system.</p>
                    </div>
                </VerSection>

            </LightBG>

            <DarkBG id="audit">
                <HorHeader 
                    top="Conducting a"
                    bottom="product audit"
                    body="For every product offering, we documented varience in components and their uses. We also checked colour contrast and typography inconsistencies to drive the value of a new design system."/>

                <div className='container-1200'>
                    <img className='span-4' src={Final} />
                </div>

            </DarkBG>

            <LightBG id="design-principles">
                <VerSection
                    top="Setting"
                    bottom="design principles"
                    body="We defined design principles to guide our design explorations. These principles centered around both end users and the eventual designers who would use the system."
                    >
                    
                        <div className='span-1'>
                            <h3>01</h3>
                        </div>
                        <div className='span-3'>
                            <h3>Accessible</h3>
                            <p>Since our users are older, accessibility was our top priority. Our colour system had to pass AA colour contrast and text styles eligible.</p>
                        </div>
                        <div className='span-1'>
                            <h3>02</h3>
                        </div>
                        <div className='span-3'>

                            <h3>Easy to use</h3>
                            <p>We have to build a robust, modular, scalable and maintainable system that makes designing easy for designers on the Boston Scientific team.</p>
                        </div>

                        <div className='span-1'>
                            <h3>03</h3>
                        </div>
                        <div className='span-3'>
                            <h3>Approachable</h3>
                            <p>As a health tech company, apps need to impart a feeling of trust. We wanted the system to feel comfortable but also trustworthy.</p>
                        </div>
                </VerSection>
            </LightBG>

            <DarkBG id="explorations">
                <HorHeader
                    top="Exploring styles"
                    bottom="and layouts"
                    body="We tried different visual treatments and considered a range styles from fitness apps to something more playful. Here is a small range of ideas we explored."/>
  
                    <div className='container-1200'>
                        <img className='span-4' src={Bscexplore} />
                    </div>
            </DarkBG>

            <LightBG id="pictograms">
                <HorHeader
                    top="Pictograms—icons for"
                    bottom="pain, sleep & activity"
                        body="Pictograms are images used to represent pain, activity and sleep in the Boston Scientific System. They have three severity levels: less, same and more. I had a hypothesis that existing icons were too similar, making it difficult to understand what they represented."/>

                <div className='container-1200'>
                    <div className='col-2-grid'>
                        <div className='span-1'>
                            <img src={Bscold} />
                                <p className='text-center'>Existing: Each pictogram set is hard to identify.</p>
                        </div>
                        <div className='span-1'>
                            <img src={Bscnew} />
                                <p className='text-center'>Proposed: Each pictogram belongs to a well defined set with notable variance within each set.</p>
                        </div>

                    </div>

                </div>

            </LightBG>

            <DarkBG id="usability-testing">
                <HorHeader
                    top="Pictogram"
                    bottom="usability testing"
                        body="My colleague, Sam, led testing for the pictograms. He tested the pictograms both in context and without context and colour taken away. Clarity of the existing pictograms were tested with 107 people and the proposed ones with 50 people."/>
                <div className='container-1200'>
                    <div className='case-section-title-cont col-2-grid mb-24 bsc-usertest'>
                        <div className='span-2'>
                            <p>Question Type 1:</p>
                        </div>
                        <div className='span-1'>
                            <h3>Which Icon represents that you ________?</h3>

                        </div>
                        <div className='span-1'>
                            <p>Colour and context was stripped. We asked testers to identify a pictogram. For example, "Which Icon represents more pain?”</p>
                        </div>
                        <div className='span-2 col-2-grid'>
                            <div className='span-1'>
                            <img src={Bscold1} />
                                    <p className='text-center'>Existing:Average 59% correct</p>
                            </div>
                            <div className='span-1'>
                            <img src={Bscnew1} />
                                    <p className='text-center'>Proposed: Average 82% correct</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-1200'>
                        <div className='case-section-title-cont col-2-grid mb-24 bsc-usertest'>

                        <div className='span-2'>
                            <p>Question Type 2:</p>
                        </div>
                        <div className='span-1'>
                            <h3>Find the icon inside the purple box. What category does it belong to?</h3>

                        </div>
                        <div className='span-1'>
                            <p>For this set of questions, colour and in app context was added. We asked testers to identify the category of the pictogram in the purple box.</p>
                        </div>
                        <div className='span-1'>
                            <div>
                                <img src={Bsc2old} />
                                    <p className='text-center'>Existing:Average 59% correct</p>
                            </div>

                        </div>
                        <div className='span-1'>
        
                            <div>
                                <img src={Bsc2new} />
                                    <p className='text-center'>Proposed: Average 82% correct</p>
                            </div>
                        </div>
                    </div>
                </div>
            </DarkBG>
            
            <LightBG id="results">
                <HorHeader
                    top="Usability test"
                    bottom="results"
                    body="Not only did users correctly guess the proposed icon more often, the distribution of guesses also decreased. We found that colour was a huge factor in how well the pictograms performed."/>
                <div className='container-1200'>
                    <div className='case-section-title-cont col-2-grid mb-24'>
                        <div className='span-1'>
                            <div>
                                <img src={Bscoldres1} />
                            </div>

                        </div>
                        <div className='span-1'>

                            <div>
                                <img src={Bscnewres2} />
                            </div>
                        </div>


                    </div>
                </div>
            </LightBG>

            <DarkBG id="iterations">
                <HorHeader
                    top="Iterations and"
                    bottom="explorations"
                    body="I iterated over many different styles to finally land on one. The final set was further refined for consistency. We delivered 48px and 24px sized pictograms with 2px stroke width."/>
                <div className='container-1200'> 
                    <img src={Bsciter1}/>
                    <img src={Bsciter2}/>
                </div>
            </DarkBG>

            <LightBG id="northstar-screens">
                <HorHeader
                    top="North star"
                    bottom="example screens"
                    body="North star screens were created to guide the Boston Scientific design team on how to use the new design system. Here are a few examples that use the pictograms I created"/>
                <div className='container-1200'>
                    <img className='span-4' src={NorthStar} />
                </div>

            </LightBG>
        
        </div>
    );

}
export default BSC;