import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from './Home/Home.js'
import Topnav from './Navbar/Navbar.js';
import About from './About/About.js';
import Fun from './Fun/Fun.js';
import Articles from './Articles/Articles.js';
import Photography from './Photography/Photography.js';
import BSC from './projects/BSC/BSC.js';
import FigmaNotifs from './projects/FigmaNotifs/FigmaNotifs.js';
import Mi from './projects/Mi/Mi.js';
import Menti from './projects/Menti/Menti.js';
import Finary from './projects/Finary/Finary.js';
import Blueprint from './projects/Blueprint/Blueprint.js';
import Rom from './projects/Rom/Rom.js';
import Meta from './projects/Meta/Meta.js';
import Footer from './Footer/Footer.js';
import NotFound from './NotFound/NotFound.js';
import Password from './Password/Password.js';

function privateRoute(path, element) {
  if (localStorage.getItem('taamannaeAuth')) {
    return element
  } else {
    return (<Password path={path}/>)
  }
}

function App() {
  return (
    <div className="App">

      
      <Topnav/>
      <Routes>
          <Route exact path="/about" element={<About/>} />
          <Route exact path="/fun" element={<Fun/>} />
          <Route exact path="/photography" element={<Photography />} />
          <Route exact path="/articles" element={<Articles/>} />
          <Route exact path="/projects/blueprint" element={<Blueprint/>} />
          <Route exact path="/projects/bsc" element={privateRoute("/projects/bsc", <BSC />)} />
          <Route exact path="/projects/figma/notifications" element={<FigmaNotifs/>} />
          <Route exact path="/projects/finary" element={<Finary/>} />
          <Route exact path="/projects/menti" element={<Menti/>} />
          <Route exact path="/projects/meta" element={<Meta/>} />
          <Route exact path="/projects/mi" element={<Mi/>} />
          <Route exact path="/projects/rom" element={<Rom/>} />
          <Route path="*" element={<NotFound/>} />
        <Route exact path="/" element={<Home />}>

        </Route>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
