import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../images/Logo.svg'
import './Navbar.scss'

function Topnav() {
    let location = window.location.pathname;

    let work = <Nav.Link href="/"> Work </Nav.Link>;
    let fun = <Nav.Link href="/fun"> Fun </Nav.Link>;
    let about = <Nav.Link href="/about"> About </Nav.Link>;

    if (location === "/") {
        work = <Nav.Link href="/" className='active-nav'> Work </Nav.Link>
    } else if (location === "/fun") {
        fun = <Nav.Link href="/fun" className='active-nav'> Fun </Nav.Link>
    } else if (location === "/about") {
        about = <Nav.Link href="/about" className='active-nav'> About </Nav.Link>
    }

    return (
        // <Fade triggerOnce>
        <Navbar expand="lg" className="top-navbar" >
            <div className="grad-blur">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className='flex-1'>
                <Navbar.Brand href="/"><img src={Logo} alt="logo"/></Navbar.Brand>
            </div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"></Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav" >
                    <Nav>
                        {work}
                        {fun}
                        {about}
                    <Nav.Link eventKey={2} href="/Tammy_Taabassum_resume.pdf" >Resume</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
        </Navbar>
        // </Fade>
    );
};

export default Topnav;