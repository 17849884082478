import React, {useState} from 'react';
import '../utilities/utils.scss';
import Zoom from 'react-medium-image-zoom'

import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import pic0 from "./design/0.png"
import pic1 from "./design/1.png"
import pic2 from "./design/2.png"
import pic3 from "./design/3.png"
import pic4 from "./design/4.png"
import pic5 from "./design/5.png"
import pic6 from "./design/6.png"
import pic7 from "./design/7.png"
import pic8 from "./design/8.png"
import pic9 from "./design/a.png"
import pic10 from "./design/b.png"
import pic11 from "./design/c.png"
import pic12 from "./design/d.png"
import pic13 from "./design/e.png"
import pic14 from "./design/f.png"
import pic15 from "./design/g.png"
import pic16 from "./design/h.png"
import pic17 from "./design/i.png"
import pic18 from "./design/j.png"
import pic19 from "./design/k.png"
import pic20 from "./design/l.png"
import pic21 from "./design/m.png"
import pic22 from "./design/muex.png"
import pic23 from "./design/lemons.png"
import pic24 from "./design/toronto.png"
import pic25 from "./design/moment2.png"
import pic27 from "./design/2169.png"
import pic28 from "./design/2171.png"
import pic29 from "./design/bahen.png"
import pic30 from "./design/daily008.png"
import pic31 from "./design/mu2.png"



//art
import art1 from "./art/1.jpg"
import art2 from "./art/2.jpg"
import art3 from "./art/3.jpeg"
import art4 from "./art/4.jpeg"
import art5 from "./art/5.jpg"
import art6 from "./art/6.jpg"
import art7 from "./art/7.jpg"
import art8 from "./art/8.png"
import art9 from "./art/9.jpg"
import art10 from "./art/10.jpg"
import art11 from "./art/11.jpg"




//travel
import travel1 from "./photos/1.jpg"
import travel2 from "./photos/2.jpg"
import travel3 from "./photos/3.jpg"
import travel4 from "./photos/4.jpg"
import travel6 from "./photos/6.jpg"
import travel7 from "./photos/7.jpg"
import travel8 from "./photos/8.jpg"
import travel9 from "./photos/9.jpg"
import travel11 from "./photos/11.jpg"
import travel13 from "./photos/13.jpg"
import travel16 from "./photos/16.jpg"
import travel17 from "./photos/17.jpg"
import travel18 from "./photos/18.jpg"
import travel19 from "./photos/19.jpg"
import travel20 from "./photos/20.jpg"
import travel21 from "./photos/21.jpg"
import travel22 from "./photos/22.jpg"
import travel23 from "./photos/23.JPG"
import travel24 from "./photos/24.JPG"
import travel25 from "./photos/25.JPG"
import travel26 from "./photos/26.JPG"
import travel27 from "./photos/27.JPG"
import travel28 from "./photos/28.JPG"
import travel29 from "./photos/29.JPG"
import travel30 from "./photos/30.JPG"


//baking
import bk1 from "./baking/1.JPG"
import bk2 from "./baking/2.jpg"
import bk3 from "./baking/3.jpeg"
import bk4 from "./baking/4.jpeg"
import bk5 from "./baking/5.jpeg"
import bk6 from "./baking/6.jpeg"
import bk7 from "./baking/7.jpeg"
import bk8 from "./baking/8.jpeg"
import bk9 from "./baking/9.jpeg"
import bk10 from "./baking/10.jpeg"
import bk12 from "./baking/12.jpeg"
import bk13 from "./baking/13.jpeg"
import bk14 from "./baking/14.jpeg"
import bk15 from "./baking/15.jpeg"
import bk16 from "./baking/16.jpg"
import bk17 from "./baking/17.jpg"


const DESIGN = [
    pic24,
    pic25,
    pic27,
    pic28,
    pic29,
    pic30,
    pic31,
    pic0,
    pic1,
    pic2,
    pic3,
    pic4,
    pic5,
    pic6,
    pic7,
    pic8,
    pic9,
    pic10,
    pic11,
    pic12,
    pic13,
    pic14,
    pic15,
    pic16,
    pic17,
    pic18,
    pic19,
    pic20,
    pic21,
    pic22,
    pic23,

]

const DESIGN_INFO = (<div className='col-2-grid'><p>When inspiration strikes or I want to challenge myself, I design purely for fun—whether it's slide decks, illustrations, icons, or interfaces. I love learning and continually refining my craft!"</p> <p>This creative work has honed my eye for detail and enabled me to wear multiple hats, from graphic designer to iconographer. It’s also been helpful when my project lacked a dedicated person for certain tasks.</p></div>)

const PHOTOGRAPHY = [
    travel25,
    travel30,
    travel29,
    travel28,
    travel27,
    travel26,
    travel24,
    travel1,
    travel2,
    travel3,
    travel4,
    travel6,
    travel7,
    travel8,
    travel9,
    travel11,
    travel13,
    travel16,
    travel17,
    travel18,
    travel19,
    travel20,
    travel21,
    travel22,
    travel23,
    travel24,
]

const PHOTOGRAPHY_INFO =  (<div className='fun-grid col-2-grid'>
    <p>I’m passionate about learning new cultures, histories, geological formations, and local cuisines. My travels strike a balance between city sightseeing, exploring nature, and enjoying some downtime. Wherever I go, I always love capturing moments through photography.</p>
        <ul>
            <li>🇨🇦</li>
            <li>🇺🇸</li>
            <li>🇨🇺 </li>
            <li>🇧🇩</li>
            <li>🇮🇳</li>
            <li>🇸🇬</li>
            <li>🇲🇻</li>
            <li>🇫🇷 </li>
            <li>🇩🇪 </li>
            <li>🇮🇹 </li>
            <li>🇨🇭</li>
            <li>🇦🇹</li>
            <li>🇸🇰</li>
            <li>🇫🇮</li>
            <li>🇩🇰 </li>
            <li>🇸🇪</li>
            <li>🇵🇹</li>
            <li>🇮🇪</li>
            <li>🏴󠁧󠁢󠁳󠁣󠁴󠁿</li>
            <li>🇪🇸</li>
            <li>🇳🇱</li>
            <li>🏴󠁧󠁢󠁥󠁮󠁧󠁿</li>
            <li>🇮🇸</li>
        </ul>
    </div>)

const ART = [
    art11,
    art10,
    art1,
    art2,
    art3,
    art4,
    art5,
    art6,
    art7,
    art8,
    art9,
]

const ART_INFO = (<div className='col-2-grid'><p>My favorite art medium is gouache, though I’ve experimented with watercolor, oil, and acrylic. Whenever time and weather permit, I love painting during hikes or walks.</p> <p>While fine art has made me a better designer, it's something I enjoy doing for the love and joy of art and the pride of mastering a craft.</p></div>)

const BAKING = [
    bk17,
    bk16,
    bk13,
    bk12,
    bk1,
    bk2,
    bk3,
    bk4,
    bk5,
    bk6,
    bk15,
    bk14,
    bk7,
    bk8,
    bk9,
    bk10,
]

const BAKING_INFO = (<div className='col-2-grid'><p>This probably needs no introduction! I love cooking and baking. More often than not, I get my inspiration from things I've tried at bakiers, resturants and friends! I'll come home and try to recreate it. </p> <p>There is something satisfying about tasting something, feeling like it's missing something and knowing exactly what to add to elevate it. I love the science of it and the puzzling behind how flavours meld together.</p></div>)


function Fun() {
    const [category, setCategory] = useState("PHOTOGRAPHY")
    const [categoryInfo, setCategoryInfo] = useState(PHOTOGRAPHY_INFO)
    const [categoryImages, setCategoryImages] = useState(PHOTOGRAPHY)
    const onOptionChange = e => {
        setCategory(e.target.value);

        if (e.target.value === 'PHOTOGRAPHY') {
            setCategoryImages(PHOTOGRAPHY)
            setCategoryInfo(PHOTOGRAPHY_INFO)
        } else if (e.target.value === 'DESIGN') {
            setCategoryImages(DESIGN)
            setCategoryInfo(DESIGN_INFO)
        } else if (e.target.value === 'ART') {
            setCategoryImages(ART)
            setCategoryInfo(ART_INFO)
        } else if (e.target.value === 'BAKING') {
            setCategoryImages(BAKING)
            setCategoryInfo(BAKING_INFO)

        }
    }

    return (
        <div className="full-bleed-cont">
            <div className='col-12-grid'>
                <div className='span-4'>
                    <h1>Fun</h1>
                    <p> Curiosity and creativity have always been central to my life. When I'm not tinkering at work, I love exploring, learning, trying new things and diving into new hobbies. Here are just a few of the things I've made. </p>

                </div>

                <div className='span-8'>

                    <ul className='form-controler'>
                        <li>
                            <input
                                type="radio"
                                value="PHOTOGRAPHY"
                                id="PHOTOGRAPHY"
                                checked={category === "PHOTOGRAPHY"}
                                onChange={onOptionChange}
                            />
                            <label htmlFor="PHOTOGRAPHY">Travel</label>

                        </li>

                        <li>
                            <input
                                type="radio"
                                value="DESIGN"
                                id="DESIGN"
                                checked={category === "DESIGN"}
                                onChange={onOptionChange}
                            />
                            <label htmlFor="DESIGN"> Design</label>

                        </li>
                        <li>
                            <input
                                type="radio"
                                value="ART"
                                id="ART"
                                checked={category === "ART"}
                                onChange={onOptionChange}
                            />
                            <label htmlFor="ART">Art</label>
                        </li>
                        <li>
                            <input
                                type="radio"
                                value="BAKING"
                                id="BAKING"
                                checked={category === "BAKING"}
                                onChange={onOptionChange}
                            />
                            <label htmlFor="BAKING">Food</label>
                        </li>
                    </ul>

                    {categoryInfo}

                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1200: 3 }} style={{marginTop: '20px'}}>
                        <Masonry columnsCount={4} gutter="12px">
                            {categoryImages.map((image, i) => {
                                return (
                                    <Zoom>
                                        <img
                                            key={i}
                                            alt=" "
                                            src={image}
                                            width="100%"
                                            style={{ width: "100%", display: "block", borderRadius: '12px' }}

                                        />
                                    </Zoom>
                                )
                            })}
                        </Masonry>
                    </ResponsiveMasonry>


                </div>


                </div>
            </div>
    );
    
}
export default Fun;