import React from "react";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import '../../utilities/utils.scss';
import Hero from '../../images/projects/bpmain.png'
import Final from '../../images/projects/blueprint.mp4'

import Arctic from '../../images/projects/arctic.jpg'
import Arctic2 from '../../images/projects/arctic2.jpg'
import Arctic3 from '../../images/projects/designsystem.jpg'
import Waiver from '../../images/projects/waiver.jpg'
import Waiveruser from '../../images/projects/waiver-user.jpg'
import Template1 from '../../images/projects/templateLeft.jpg'
import Template2 from '../../images/projects/templates1.png'

import CaseHeader, { DarkBG, LightBG, HorHeader } from '../CaseHeader';

function Blueprint() {


    return (
        <div className="case-study BSC">
            <CaseHeader
                headerImage={Hero}
                className="bsc-proj-header"
                title="Blueprint"
                subtitle="A web app for an undisclosed non-profit that supports vulnerable people with move planning and coordination"
                blurb="Blueprint is a non-profit that aids in move coordination. I worked in a team of 9 to create an event coordination, emailing and digital waiver signing system. For privacy reasons, I can only share an overview of the designs and not describe the full case study."
                problem="Blueprint used many manual processes and different applications to plan moves. How can we automate this and provide an all-in-one solution for move coordination?"
                outcome="I created a design system, led a junior designer, delivered high fidelity mockups, worked with developers, interfaced with clients and delivered a web app to improve their process."
                role="Lead designer"
                time="Sep '20 - Aug '21"
                team="2 designers, 6 engineers, 1 PM and 1 project lead"
            />
        <video loop autoplay width="100%" id="final" style={{marginTop: '-64px'}}>
         <source src={Final} type="video/mp4" />
        </video>


        <LightBG id="design-system">
            <HorHeader
                top="Building a robust"
                bottom="design system"
                body="I created a design system based on the components we built. This non-profit already had a brand guide, so our goal was to enhance the existing style guide into a robust and scalable system." />
            <div className='container-1200'>
                <div className='col-2-grid'>
                    <div className='span-1'>
                        <Zoom>
                            <img
                                alt=" "
                                src={Arctic}
                                width="100%"
                            />
                        </Zoom>
                    </div>
                    <div className='span-1'>
                        <Zoom>
                            <img
                                alt=" "
                                src={Arctic2}
                                width="100%"
                            />
                        </Zoom>
                    </div>
                    <div className='span-2'>
                        <Zoom>
                            <img
                                alt=" "
                                src={Arctic3}
                                width="100%"
                            />
                        </Zoom>
                    </div>
                </div>
            </div>

        </LightBG>

        <DarkBG id="feature-1">
            <HorHeader
                top="Feature #1"
                bottom="Digital waivers"
                body="Blueprint used to collect waivers manually, requiring volunteers to sign on the day of the move. We introduced a digital waiver signing process to speed up, automate and streamline this manual process." />

            <div className='col-2-grid'>
                <div className='span-1'>
                    <Zoom>
                        <img
                            alt=" "
                            src={Waiver}
                            width="100%"
                        />
                    </Zoom>
                </div>
                <div className='span-1'>
                    <Zoom>
                        <img
                            alt=" "
                            src={Waiveruser}
                            width="100%"
                        />
                    </Zoom>
                </div>
            </div>

        </DarkBG>

        <LightBG id="feature-2">
            <HorHeader
                top="Feature #2"
                bottom="Automation through templates"
                body="Blueprint kept templates in Google Docs for most processes but needed better control over their use. We integrated templating directly into the system so new itineraries always start with the correct template, and only managers can change which templates are used." />

            <div className='col-3-grid'>
                <div className='span-1'>
                    <Zoom>
                        <img
                            alt=" "
                            src={Template1}
                            width="100%"
                        />
                    </Zoom>
                </div>
                <div className='span-2'>
                    <Zoom>
                        <img
                            alt=" "
                            src={Template2}
                            width="100%"
                        />
                    </Zoom>
                </div>
            </div>

        </LightBG>

        <LightBG id="quote">
            <div className="bp-quote">
                <h2>"</h2>
                <h2>We're extremely lucky to have had the opportunity to collaborate with the team. Working with the team, under the creative and consistent leadership of Tammy, Dakota and Michael has been a wonderful experience. They are not only extremely diligent, but are thoughtful communicators who are very patient and listened carefully to every request and change we had for the software they are developing for us.</h2>

                <h3>Operations Director</h3>

            </div>

        </LightBG>
        </div>
    );

}
export default Blueprint;