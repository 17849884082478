import React from 'react';
import './Articles.scss';
import arrow from '../images/arrow-up-right.svg'
import { Fade } from "react-awesome-reveal";


const ARTICLES_LIST = [
    {
        title: "Immigrant Families' Health- Related Information Behavior on Instant Messaging Platforms",
        headline: 'CHI',
        date: '2021',
        desc: 'For immigrant families, instant messaging family groups are a common platform for sharing and discussing health-related information. Immigrants often maint...',
        link: 'https://dl.acm.org/doi/10.1145/3411763.3451751',
        class: 'figma',
    },

    {
        title: "A Wish Design System Color Story",
        headline: 'Medium',
        date: '2021',
        desc: 'During the winter of 2021, I had the chance to be a Product Design intern at Wish. I remember it being the first day of my internship and I was...',
        link: 'https://bootcamp.uxdesign.cc/a-wish-design-system-color-story-ef2c38de97e',
    },
    {
        title: "UX Design Projects: What do I do next, and which methodology do I use?",
        headline: 'Medium',
        date: '2020',
        desc: 'You’ve started your UX project, or maybe you were given a problem to tackle. Perhaps you’ve done the research, or maybe you’re not sure where to even begin.',
        link: 'https://uxdesign.cc/figma-design-thinking-building-a-design-system-for-an-existing-product-388bfdeb844b',
    },
    {
        title: "Figma & design thinking: Building a design system for an existing product",
        headline: 'Medium',
        date: '2020',
        desc: 'When I joined Potluck as a UX Design intern, we were using Sketch, Craft, and InVision for our design-to-development pipeline.',
        link: 'https://uxdesign.cc/figma-design-thinking-building-a-design-system-for-an-existing-product-388bfdeb844b',
    },
    {
        title: "Losing a Google UX Design Internship to COVID and What I Did About it",
        headline: 'Medium',
        date: '2020',
        desc: 'I remember when I applied for the internship. I kept refreshing the page until the application button went live. It almost felt like if I took my eyes off of it, I would...',
        link: 'https://uxdesign.cc/figma-design-thinking-building-a-design-system-for-an-existing-product-388bfdeb844b',
    },
    {
        title: "Neumorphism, visual accessibility, and empathy",
        headline: 'Medium',
        date: '2020',
        desc: "'A good UX designer has empathy.'This is something every UX designer has heard at some point in their career. Empathy helps us get into the mindset of the user and build...",
        link: 'https://uxdesign.cc/neumorphism-visual-accessibility-and-empathy-d1c5ed2a1f03',
    }, {
        title: "Expectations vs Realities: UX design in the industry vs UX design at school",
        headline: 'Medium',
        date: '2019',
        desc: "If you’ve been to design school or taken a design course or even researched UX design, you’ll know how important the design process is. Having done design classes...",
        link: 'https://uxdesign.cc/expectations-vs-realities-ux-design-in-the-industry-vs-ux-design-at-school-51851e59a5eb',
    },

]
function Articles() {
    return (
        <div className="articles col-12-grid">
            <div className='span-7 article-title'>
                <Fade direction='up' triggerOnce cascade={false}>
                    <div>
                <span>
                    <h1>Articles,</h1>
                    <h2>Papers, thoughts and odd musings</h2>
                </span>
                <p>A small collection of papers and articles I’ve written over the years—usually about design, sometimes tangentially related.</p>
                    </div>
                </Fade>
            </div>

            <div className='experience span-5 article-title'>
                <div className='col-7-grid'>
                    {renderCards()}
                </div>
            </div>
        </div>
    );
    
}

function renderCards() {
    return(
        ARTICLES_LIST.map((item, i) => {

                return (
                    <>
                        <Fade className='span-1 date'  direction='up' triggerOnce delay={i * 100 + 100}>
                            <h2>{item.date}</h2>
                        </Fade>

                        <Fade className='span-6 info' direction='up' triggerOnce delay={i * 100 + 150} cascade={false}>
                            <div>
                            <h2><a href={item.link}>{item.title}<span><img src={arrow} /></span></a></h2>
                            <p>{item.desc}</p>
                            </div>
                        </Fade>
                    </>
                );

            })
        )

}

export default Articles;