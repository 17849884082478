import React from "react";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import './Mi.scss';
import '../../utilities/utils.scss';
import Mihero from '../../images/projects/mimain.png'
import Final from '../../images/projects/misched2.mp4'
import Miold from '../../images/projects/miold.jpg'
import Mileft from '../../images/projects/mi-left.jpg'
import Miright from '../../images/projects/mi-right.jpg'
import Mifeedback from '../../images/projects/mi-feedback.jpg'
import Mistats from '../../images/projects/mistats.png'
import CaseHeader, { DarkBG, LightBG, HorHeader } from '../CaseHeader';

function Mi() {


    return (
        <div className="case-study BSC">
            <CaseHeader
                headerImage={Mihero}
                className="bsc-proj-header"
                title="MI Scheduler"
                subtitle="A web app for building a course schedule for the Master of Information"
                blurb="For the fall & winter semester of the Master of Information program, the faculty released the courses offered on an excel sheet. This excel sheet made the process of creating a class schedule cumbersome. You'd have to look up classes and use a third party time table scheduler. I believed there could be an easier way to build a fall & winter timetable."
                problem="How can we make it easier for MI students to build a course schedule at the University of Toronto?"
                outcome="I built a course scheduler within a week and reached 81 visiters the day before course selection."
                role="Product designer & developer"
                time="1 week"
            />
            <video id="final-system" loop autoplay width="100%" style={{marginBottom: '-7px'}}>
                <source src={Final} type="video/mp4"/>
            </video>

        <DarkBG id="problem">
            <HorHeader
                top="Spreadsheets make"
                bottom="course planning hard"
                        body="The fall and winter course offerings were in two Excel sheets. Students had to manually look up each class and use third-party apps to build a schedule. Some used Figma to visualize it, while others simply imagined it. As a result, selecting classes took much longer than expected." />

            <div className='container-1200'>
            <Zoom>
                <img
                    alt=" "
                    src={Miold}
                    width="100%"
                />
            </Zoom>
            </div>
        </DarkBG>

         
        <LightBG id="solution">
            <HorHeader 
                top="The solution:"
                bottom="an interactive class schedule builder"
                        body="I converted the Excel sheet into a JSON object for easier parsing. Using a fuzzy search library, users can search for class codes or titles, then add lecture, tutorial, or practical times. Users can click on added classes to update them. After receiving feedback, I added a button to remove classes."/>

            <div className='col-3-grid'>
                <div className='span-1'>
                    <Zoom>
                        <img
                            alt=" "
                            src={Mileft}
                            width="100%"
                        />
                    </Zoom>
                </div>
                <div className='span-2'>
                    <Zoom>
                        <img
                            alt=" "
                            src={Miright}
                            width="100%"
                        />
                    </Zoom>
                </div>
            </div>

        </LightBG>

        <DarkBG id="iterations">
            <HorHeader
                top="Positive feedback"
                bottom="and future requests"
                        body="After my initial launch, I asked my peers to test the interface for feedback. They requested options to remove classes and export the schedule to a calendar. I used this feedback to do one more iteration." />

            <div className='container-1200'>
                <Zoom>
                    <img
                        alt=" "
                        src={Mifeedback}
                        width="100%"
                    />
                </Zoom>
            </div>

        </DarkBG>

        <LightBG id="metrics">
            <HorHeader
                top="Outcomes and"
                bottom="metrics"
                body="Since its initial launch in 2021, my tool has had 539 new users and 1.3k sessions. I have continued to update the site for every new year, which is highlighted by the spikes in usage in the fall." />
            <div className='container-1200'>
                <Zoom>
                    <img
                        alt=" "
                        src={Mistats}
                        width="100%"
                    />
                </Zoom>
            </div>

        </LightBG>
        </div>
    );

}
export default Mi;