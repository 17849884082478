import React, {useState} from 'react';
import Logo from '../images/Logo.svg'
import './Footer.scss'
import '../utilities/utils.scss'
import Twitter from '../images/twitter.svg';
import LinkedIn from '../images/li.svg';
import Mail from '../images/mail.svg';

let COPY = [
    'Remade with </> far too many times to count',
    'Made without ☕️ becasue I hate coffee',
    'Made by the dying firelight of a supernova ✨',
    'Coded in the twilight of endless refactors',
    'Forged with pixels and a sprinkle of chaos',
    'Conjured by the whispers of ancient algorithms',
    'Imagined while procrastinating on something important',
    'Assembled with the grace of a cat knocking over everything 🐾💥',
    'Forged in the fires of “it worked on my machine” 🔥',
    'Imagined in a fit of “I’ll fix it later” optimism ✨',
]

function Footer() {
    let currentDate = new Date(); 
    let currentYear = currentDate.getFullYear()

    return (
        <footer className='flex space-between'>
                <h4>
                © {currentYear} · {COPY[Math.floor(Math.random() * COPY.length)]}
                </h4>
                <div><div className='flex socials'>
                    <a href="https://x.com/taamannae" rel="noreferrer" target='_blank'><img src={Twitter} /></a>
                    <a href="https://www.linkedin.com/in/taamannae/" rel="noreferrer" target='_blank'><img src={LinkedIn} /></a>
                    <a href="mailto:taamannae@gmail.com"><img src={Mail} /></a>
                </div></div>
        </footer>
    );
};

export default Footer;