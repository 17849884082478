import React, {useState} from 'react';
import arrowLeft from '../images/arrow-left.svg'
import { useNavigate } from "react-router-dom";
import './Password.scss'
import '../utilities/utils.scss'
import lock from '../images/projects/lock.svg'

function Password(path) {
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [showError, setShowError] = useState(false);
    function handleChange(e) {
        setPassword(e.target.value);

    }
    function onClick() {
        if (password === process.env.REACT_APP_PASSWORD) {
            localStorage.setItem('taamannaeAuth', true)
            navigate(path);
            window.location.reload();

        } else {
            setShowError(true)
        }
    }

    return (
        <div className='password-page'>
            <a href="/" className='back-button'><img src={arrowLeft} alt="back icon"/></a>
            <div className='contents'>
                <div className='lock'><img src={lock} alt="lock" /></div>
                <div><h1>Password please!</h1>
                    <p>This page is protective. If you'd like to learn more, please reach out to me!</p></div>
                <div className='password-form'>
                    <input type="password"  onChange={handleChange} placeholder='Password'/>
                    <button onClick={onClick}> Submit</button>

                    {showError ? <div className='error'> Sorry, wrong password</div> : ''}
                </div>
            </div>
        </div>
    );
};

export default Password;