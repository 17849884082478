import React from 'react';
import '../utilities/utils.scss';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import pic1 from "./photos/1.jpg"
import pic2 from "./photos/2.jpg"
import pic3 from "./photos/3.jpg"
import pic4 from "./photos/4.jpg"
import pic5 from "./photos/5.jpg"
import pic6 from "./photos/6.jpg"
import pic7 from "./photos/7.jpg"
import pic8 from "./photos/8.jpg"
import pic9 from "./photos/9.jpg"
import pic10 from "./photos/10.jpg"
import pic11 from "./photos/11.jpg"
import pic12 from "./photos/12.jpg"
import pic13 from "./photos/13.jpg"
import pic14 from "./photos/14.png"
import pic15 from "./photos/15.jpg"
import pic16 from "./photos/16.jpg"
import pic17 from "./photos/17.jpg"
import pic18 from "./photos/18.jpg"
import pic19 from "./photos/19.jpg"
import pic20 from "./photos/20.jpg"
import pic21 from "./photos/21.jpg"
import pic22 from "./photos/22.jpg"
import pic23 from "./photos/23.JPG"
import pic24 from "./photos/24.JPG"
import { Fade } from "react-awesome-reveal";

const images = [
    pic24,
    pic23,
    pic22,
    pic21,
    pic19,
    pic16,
    pic10,
    pic4,
    pic5,
    pic6,
    pic7,
    pic8,
    pic9,
    pic3,
    pic11,
    pic12,
    pic13,
    pic14,
    pic15,
    pic2,
    pic17,
    pic18,
    pic1,
    pic20,
]

function Photography() {

    return (
        <div className="full-bleed-cont"> 
            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1200: 4 }}>
                <Masonry gutter="12px">
                    <Fade direction='up' triggerOnce cascade>
                    {images.map((image, i) => (
                        <img
                            key={i}
                            src={image}
                            style={{ width: "100%", display: "block", borderRadius: '12px' }}
                        />
                    ))}
                    </Fade>
                </Masonry>
            </ResponsiveMasonry>
        
        </div>
    );

}
export default Photography;