import React from 'react';
import './FigmaNotifs.scss';
import '../../utilities/utils.scss';
import Fighero from '../../images/projects/figheader.png'
import CaseHeader from '../CaseHeader';

function FigmaNotifs() {
    return (
        <div className="case-study BSC">
            <CaseHeader
                headerImage={Fighero}
                className="figma-proj-header"
                title="Figma notifications"
                subtitle="Improving the information architecture, visual design and scanability of Figma’s notifications"
                blurb="Boston Scientific is a health tech company focused on improving the health of their users with innovative technologies. Boston Scientific wished to create a more cohesive design across all of their mobile applications. This case study focuses on my work on icons."
                problem="Create a cohesive design system that is also accessible to Boston Scientific's older users and that can be used across all apps."
                outcome="We delivered a design system in 4 months. I worked on a icon set that improved in context recognition from 56% to 98%"
                role="Product designer"
                time="Sep - Dec 2020"
                team="5 designers"
            />
        </div>
    );

}
export default FigmaNotifs;