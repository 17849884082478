import React from "react";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import '../../utilities/utils.scss';
import Hero from '../../images/projects/mentimain.png'
import MentiBg from '../../images/projects/menti-bg.jpg'
import Final from '../../images/projects/menti-vid.mp4'

import Menmen1 from '../../images/projects/men-men-1.jpg'
import Menmen2 from '../../images/projects/men-men-2.jpg'
import Menscr1 from '../../images/projects/men-screen-1.jpg'
import Menscr2 from '../../images/projects/men-screen-2.jpg'
import Meninfo1 from '../../images/projects/men-info-1.jpg'
import Meninfo2 from '../../images/projects/men-info-2.jpg'

import Menwhite1 from '../../images/projects/men-whiteboarding-1.jpg'
import Menwhite2 from '../../images/projects/men-whiteboarding-2.jpg'
import Mencode from '../../images/projects/men-code.jpg'
import Night from '../../images/projects/night.jpeg'
import Whiteboarding from '../../images/projects/whiteboard.mov'

import CaseHeader, { DarkBG, LightBG, HorHeader, VerSection } from '../CaseHeader';

function Menti() {


    return (
        <div className="case-study BSC">
            <CaseHeader
                headerImage={Hero}
                className="bsc-proj-header"
                title="Menti"
                subtitle="Class project—a platform for designers to practice for design interviews and gain mentorship"
                blurb="For a class during my time in grad school, we were tasked with creating a platform that filled an unmet need. My partner, Amanda, and I decided to explore design interview preparation as a domain. We designed coded and ran a very quick usability test within 3 weeks. Given then short turn around, the goal for this project was to build a solution and not to make the most effective solution."
                problem="How might support designers who want to practice whiteboarding, app critques and portfolio presentations?"
                outcome="We designed and coded a prototype for supporting designers with design interviews in 2 weeks."
                role="Product designer & developer"
                time="3 week"
                team="2 designers"
            />

        <div className="menti-intro-cont" id="final">
            <img src={MentiBg}/>
                <video loop autoplay width="100%" className="mento-video">
                <source src={Final} type="video/mp4" />
            </video>

        </div>

        <DarkBG id="process">
            <VerSection
                top="Concept, designs "
                bottom="& code prototype in 3 weeks"
                body="I'm proud of this project because we were able to design and code a static prototype from scratch within two weeks. While we did conduct usability test, the goal was to build something. Here is how the work was broken down.">
                <div className='span-1'>
                    <h3>01</h3>
                </div>
                <div className='span-3'>
                    <h3>Week 1—Research & Design</h3>
                    <p>Amanda and I ran a quick survey to understand current experiences with a total of 15 responses. I designed the first few set of screens to define the visual style and information architecture.</p>
                </div>

                <div className='span-1'>
                    <h3>02</h3>
                </div>
                <div className='span-3'>
                    <h3>Week 2—Code & Design</h3>
                    <p>Once the overall basic designs were set, I coded the whole thing using ReactJS and Bootstrap as a base for the UI library. Amanda took over and designed the rest and we collaborated on changes as needed before I coded the rest.</p>
                </div>

                <div className='span-1'>
                    <h3>03</h3>
                </div>
                <div className='span-3'>
                    <h3>Week 3—Usability test & paper</h3>
                    <p>Amanda and I ran 8 usability tests to test if the product was usable and useful and wrote up our paper for the project.</p>
                </div>
            </VerSection>


        </DarkBG>


        <LightBG id="research">
            <VerSection
                top="User research"
                bottom="results & findings"
                body="Given the quick turn around, we conducted a small survey with 15 people. Most of these individuals are new/junior designers. Here are the results:">
                <div className='span-1'>
                    <h3>87%</h3>
                </div>
                <div className='span-3'>
                    <h3>of the survey takers ask for help from friends but the advice isn't always great</h3>
                    <p>Junior designers often practice interviews with friends because they find it easier and more relaxed. However, friends don't always provide the best advice.</p>
                </div>

                <div className='span-1'>
                    <h3>85%</h3>
                </div>
                <div className='span-3'>
                    <h3>of survey takers trust designers based on years of experience</h3>
                    <p>This was followed closely by a "senior" title and if the designer works for a FAANG company. Some survey takers trust help from others only if they work for the specific company they are applying for.</p>
                </div>

            </VerSection>


        </LightBG>
        <DarkBG id="feature-1">
            <HorHeader 
                top="Feature #1"
                bottom="Mentorship"
                        body="The best place to get interview practice is from senior designers. We added a mentorship feature as a default experience. Users sign up for interview practice sessions with more experienced designers."/>

            <div className='col-2-grid'>
                <div className='span-1'>
                    <Zoom>
                        <img
                            alt=" "
                            src={Menmen1}
                            width="100%"
                        />
                    </Zoom>
                </div>
                <div className='span-1'>
                    <Zoom>
                        <img
                            alt=" "
                            src={Menmen2}
                            width="100%"
                        />
                    </Zoom>
                </div>
            </div>

        </DarkBG>
        <LightBG id="feature-2">
            <HorHeader 
                top="Feature #2"
                bottom="Phone screen sharing for app critques"
                        body="Mobile app critiques are challenging due to the complexity of sharing phone screens. We simplified this process by allowing users to easily share their screens by scanning a QR code."/>

            <div className='col-3-grid'>
                <div className='span-1'>
                    <Zoom>
                        <img
                            alt=" "
                            src={Menscr1}
                            width="100%"
                        />
                    </Zoom>
                </div>
                <div className='span-2'>
                    <Zoom>
                        <img
                            alt=" "
                            src={Menscr2}
                            width="100%"
                        />
                    </Zoom>
                </div>
            </div>

        </LightBG>
        <DarkBG id="feature-3">
            <HorHeader
                top="Feature #3"
                bottom="Company guides"
                body="Interviewing can be tough when you don't know what companies want in designers. We've added employee endorsed company guides with insider tips and tricks to show exactly what interviewers are looking for." />

            <div className='col-2-grid'>
                <div className='span-1'>
                    <Zoom>
                        <img
                            alt=" "
                            src={Meninfo1}
                            width="100%"
                        />
                    </Zoom>
                </div>
                <div className='span-1'>
                    <Zoom>
                        <img
                            alt=" "
                            src={Meninfo2}
                            width="100%"
                        />
                    </Zoom>
                </div>
            </div>

        </DarkBG>
        <LightBG id="feature-4">
            <HorHeader
                top="Feature #4"
                bottom="Whiteboarding and prompt generator"
                body="Practicing whiteboarding interviews is challenging due to finding prompts and screensharing. We added a whiteboard option for mentorship calls with a prompt generator, so everything needed is in one place." />

            <div className='col-3-grid'>
                <div className='span-1'>
                    <Zoom>
                        <img
                            alt=" "
                            src={Menwhite1}
                            width="100%"
                        />
                    </Zoom>
                </div>
                <div className='span-2 men-whiteboarding' style={{backgroundImage: `url('${Menwhite2}')`, backgroundSize: 'cover'}}>
  
                    <video loop autoplay width="80%">
                        <source src={Whiteboarding} type="video/mp4" />
                    </video>

                </div>
            </div>

        </LightBG>

        <DarkBG id="code">
            <HorHeader
                top="Coding a prototype"
                bottom="from scratch"
                body="Coding this was my favourite part. I used ReactJS and Bootstrap for the base design system. With no backend, all of the data was mocked-up using JSON to mimic a backend." />

            <div className='container-1200'>
                <Zoom>
                    <img
                        alt=" "
                        src={Mencode}
                        width="100%"
                    />
                </Zoom>
            </div>

        </DarkBG>
        <LightBG id="prototype">
            <HorHeader
                top="Final designs and"
                bottom="coded prototype"
                        body="With that, here is the prototype hosted on netlify. We also did 8 usability tests with this prototype to determine the design's effectiveness" />

            <div className='men-whiteboarding fixed-height' style={{ backgroundImage: `url('${Night}')`, backgroundSize: 'cover' }}>


                    <video loop autoplay width="60%">
                        <source src={Final} type="video/mp4" />
                    </video>

                </div>

        </LightBG>
        </div>
    );

}
export default Menti;