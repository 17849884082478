import React from "react";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import '../../utilities/utils.scss';
import Hero from '../../images/projects/metamain.png'

import Metatimeline from '../../images/projects/metatimeline.svg'
import Highfive from '../../images/projects/highfive.jpg'
import Metapeeps from '../../images/projects/metapeeps.png'

import Funtimes from '../../images/projects/funtimes.jpg'

import CaseHeader, { DarkBG, LightBG, ScrollMenu, ScrollMenuItem, HorHeader, VerSection } from '../CaseHeader';
import ScrollSpy from "react-ui-scrollspy";

function Meta() {
    return (
        <div className="case-study BSC">
            <ScrollMenu>
                <ScrollMenuItem href="#responsibility" id="responsibility" title="Responsibilities"/>
                <ScrollMenuItem href="#timeline" id="timeline" title="Timeline"/>
                <ScrollMenuItem href="#collab" id="collab" title="Collaboration"/>
                <ScrollMenuItem href="#metrics" id="metrics" title="Metrics"/>
                <ScrollMenuItem href="#team" id="team" title="Team"/>
                <ScrollMenuItem href="#fun" id="fun" title="Fun"/>
                <ScrollMenuItem href="#reflection" id="reflection" title="Reflection"/>
            </ScrollMenu>
            <CaseHeader
                headerImage={Hero}
                className="bsc-proj-header"
                title="Meta"
                subtitle="My time at Meta as a Product Design Intern on the horizontal team called Product Foundations"
                blurb="I spent the summer of 2022, having an absolute blast working in-person at Meta as a product designer. I learned how important it was to keep cross functional teams in sync with your work. This goes beyond just dev, DS or engineering. It also includes designers on other teams who have stake in the work you are doing. My projects focused on the unification of different experiences on Facebook. My work is under NDA. This case study only shows my main learnings"
                role="Product designer & developer"
                time="3 week"
                team="2 designers"
            />
        <ScrollSpy
                activeClass="ss-active"
                offsetBottom={100}
                scrollThrottle={80}
                useBoxMethod
            >

        <DarkBG id="responsibility">
            <VerSection
                top="Overview and"
                bottom="responsibility"
                body="I was given permission to make my internship exactly what I wanted. I had a core project but I was encouraged to take on everything and anything I wanted. Here are some of my responsibilities.">
                <div className='span-1'>
                    <h3>01</h3>
                </div>
                <div className='span-3'>
                    <h3>Gaining Context</h3>
                    <p>To better understand the problem, I reviewed existing data and research from Facebook. I also had 1:1 meetings with PMs, engineers, and designers from various teams to learn more about the problem space.</p>
                </div>

                <div className='span-1'>
                    <h3>02</h3>
                </div>
                <div className='span-3'>
                    <h3>Designing</h3>
                    <p>I used Figma and the Facebook design system to design solutions for 3 product areas. I iterated over solutions and led a brainstorming session in FigJam. I also built a clickable prototype.</p>
                </div>

                <div className='span-1'>
                    <h3>03</h3>
                </div>
                <div className='span-3'>
                    <h3>Coding Prototypes & fixing bugs</h3>
                    <p>With my engineering background, I was able to code out a prototype of my core project to understand how seamless of an experience it could be. I was also ale to fix small UI bugs on Facebook that was pushed out to Facebook's billions of users.</p>
                </div>
                <div className='span-1'>
                    <h3>04</h3>
                </div>
                <div className='span-3'>
                    <h3>Updating Design Systems & building frameworks</h3>
                    <p>Since I was part of the product foundations team, I supported the design system for Facebook web. I made updates to the design system and wrote frameworks for multiple systems</p>
                </div>
            </VerSection>


        </DarkBG>
        <LightBG id="timeline">
            <HorHeader
                top="Project"
                bottom="timelines"
                    body="Although I can't share the details of my work, here is my basic timeline. My favourite part was building a prototype and breaking down a large project into smaller sections for testing."/>


        <div className="container-1200">
            <Zoom>
                <img
                    alt=" "
                    src={Metatimeline}
                    width="100%"
                />
            </Zoom>
        </div>

        </LightBG>
        <DarkBG id="collab">
            <HorHeader 
                top="Hybrid model"
                        bottom="of collaboration"
                        body="Although the internship was in person, many of my team members worked remotely or in a hybrid setup. It felt a bit lonely so I tried to find ways to connect with my team. Figma helped a lot! Here we are high-fiving each other during a brainstorming workshop I ran for my core project."/>
                <div className="container-1200">
                    <Zoom>
                        <img
                            alt=" "
                            src={Highfive}
                            width="100%"
                        />
                    </Zoom>
                </div>

        </DarkBG>
        <LightBG id="metrics">
                    <VerSection
                        top="Summer"
                        bottom="statistics"
                        body="My internship was full of fun achievements. Here are the numbers that represented my time at Meta">
                        <div className='span-1'>
                            <h3>4,773</h3>
                        </div>
                        <div className='span-3'>
                            <h3>Lines of code</h3>
                            {/* <p>To better understand the problem, I reviewed existing data and research from Facebook. I also had 1:1 meetings with PMs, engineers, and designers from various teams to learn more about the problem space.</p> */}
                        </div>

                        <div className='span-1'>
                            <h3>33</h3>
                        </div>
                        <div className='span-3'>
                            <h3>Pull requests reviewed</h3>
                            {/* <p>I used Figma and the Facebook design system to design solutions for 3 product areas. I iterated over solutions and led a brainstorming session in FigJam. I also built a clickable prototype.</p> */}
                        </div>

                        <div className='span-1'>
                            <h3>25</h3>
                        </div>
                        <div className='span-3'>
                            <h3>Figma Design system variants updated</h3>
                            {/* <p>With my engineering background, I was able to code out a prototype of my core project to understand how seamless of an experience it could be. I was also ale to fix small UI bugs on Facebook that was pushed out to Facebook's billions of users.</p> */}
                        </div>
                        <div className='span-1'>
                            <h3>17</h3>
                        </div>
                        <div className='span-3'>
                            <h3>Pull requests made</h3>
                            {/* <p>Since I was part of the product foundations team, I supported the design system for Facebook web. I made updates to the design system and wrote frameworks for multiple systems</p> */}
                        </div>
                        <div className='span-1'>
                            <h3>7</h3>
                        </div>
                        <div className='span-3'>
                            <h3>Facebook UI bugs fixed</h3>
                            {/* <p>Since I was part of the product foundations team, I supported the design system for Facebook web. I made updates to the design system and wrote frameworks for multiple systems</p> */}
                        </div>
                        <div className='span-1'>
                            <h3>1mil +</h3>
                        </div>
                        <div className='span-3'>
                            <h3>Snacks eaten</h3>
                            {/* <p>Since I was part of the product foundations team, I supported the design system for Facebook web. I made updates to the design system and wrote frameworks for multiple systems</p> */}
                        </div>
                    </VerSection>


        </LightBG>
        <DarkBG id="team">
            <HorHeader
                top="Core team"
                bottom="members"
                body="My team was a small group of designers and a Design Program Manager. They helped me become a better designer, each with their unique strengths that I relied on to improve my experience." />

            <div className='container-1200'>
                <Zoom>
                    <img
                        alt=" "
                        src={Metapeeps}
                        width="100%"
                    />
                </Zoom>
            </div>

        </DarkBG>
        <LightBG id="fun">
            <HorHeader
                top="Non-work related"
                bottom="activities and fun"
                body="The summer was a dream come true. I worked on exciting projects and made an impact while having fun. We tried glass blowing, pottery, went to a baseball game, and ate at a Michelin-star restaurant." />

                    <Zoom>
                        <img
                            alt=" "
                            style={{mixBlendMode: 'multiply'}}
                            src={Funtimes}
                            width="100%"
                        />
                    </Zoom>


        </LightBG>
        <DarkBG id="reflection">
            <VerSection
                top="Reflecting on"
                bottom="the project"
                body="While the summer was a blast, it came with its own challenges and learnings. Here are my main takeaways">
                    <div className='span-1'>
                        <h3>01</h3>
                    </div>
                    <div className='span-3'>
                            <h3>Keep everyone in the loop</h3>
                            <p>Working in big tech meant more siloed teams, so I had to actively get to know cross-functional teams and be vocal about my work to ensure everyone was aware and onboard with my proposed changes.</p>
                    </div>
                    <div className='span-1'>
                        <h3>02</h3>
                    </div>
                    <div className='span-3'>
                        <h3>How to work without a UX researcher</h3>
                        <p> My small team didn't have a UX researcher, so we used data science, existing research, design critiques, and small experiments. I had to learn to trust my instincts and not rely solely on user data. I also learned to break ideas into experiments and explain why.</p>
                    </div>
                    <div className='span-1'>
                        <h3>03</h3>
                    </div>
                    <div className='span-3'>
                        <h3>Trust thyself</h3>
                        <p>
                        This was one of the hardest lessons. I had imposter syndrome and felt everyone was better than me, so I hesitated to speak up. My team helped me see the value of my contributions and encouraged me to be more vocal. </p>
                    </div>
                    <div className='span-1'>
                        <h3>04</h3>
                    </div>
                    <div className='span-3'>
                        <h3>Knowing when to create prototypes</h3>
                        <p>
                        Different levels of fidelity are needed depending on where in the project we are in. I had to learn when to abandon Figma and start coding my own prototypes and articulate why it was needed. It was helpful having a mockup website to build on top of.</p>
                    </div>
            </VerSection>
        </DarkBG>
        </ScrollSpy>
        </div>
    );

}
export default Meta;